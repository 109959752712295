
/* eslint-disable */
import WebViewer from "@pdftron/pdfjs-express-viewer";
import { getInstance } from "@pdftron/pdfjs-express-viewer";

export default {
 name: "WebViewer",
 props: {
  pdfData: {
   type: Object,
   required: true,
  },
  activePage: {
   type: Number,
   required: true,
   default: 1,
  },
 },
 data() { 
   return {
    initialized: false,
   };
 },
 watch: {
  pdfData: function(updatedPdfData) {
   const inst = getInstance(this.$refs.viewer);
   if (inst !== null) {
    //little hack for navigating to first page in estaid easement.
    //The component gets mounted and therefor the pdfViewer gets created before the user
    //has chosen a easement, so we can't add a eventListener in the mounted section that will work.
    //So we need this just to navigate to the correct easement page when we choose the first act and also changing act in the dialog.
    inst.UI.addEventListener("documentLoaded", (e) => {
     inst.Core.documentViewer.setCurrentPage(updatedPdfData.page);
     inst.UI.removeEventListener("documentLoaded");
     inst.UI.openElements(["searchPanel"]);
    });
    inst.UI.loadDocument(updatedPdfData.url, { extension: "pdf" });
    inst.UI.openElements(["searchPanel"]);
   }
  },
 },
 beforeDestroy() {
   if(this.initialized) {
     const inst = getInstance(this.$refs.viewer);
     if (inst !== null) {
       inst.UI.dispose();
    }
    this.initialized = false;
   }
 },
 methods: {
  setPage(pageNr) {
   const inst = getInstance();
   inst.Core.documentViewer.setCurrentPage(pageNr);
  },
  async initWebViewer() {
   const instance = await WebViewer(
    {
     path: "/webviewer",
     //licenseKey is domain bound, so for local usage, map the domain in your hosts file.
     licenseKey: "by5ey450hDdOAmJMwuGR",
     initialDoc: "",
     disabledElements: [
      "viewControlsButton",
      "viewControlsOverlay",
      "selectToolButton",
      "panToolButton",
      "menuButton",
      "leftPanelButton",
      "contextMenuPopup",
     ],
     css: "/webviewer.css"
    },
    this.$refs.viewer
   );
   // docs: https://pdfjs.express/documentation/viewer/overview
   // api: https://pdfjs.express/api/index.html
   // localization: https://pdfjs.express/documentation/viewer/localization

   const { Core, UI } = instance;
   Core.documentViewer.enableArrowKeyNavigation();

   // can be used in the future, so its possible to just double click to add markings.
   // Core.documentViewer.addEventListener("dblClick", (doc) => {
   //   emit markPage event
   // });

   // const isOpen = UI.isElementOpen('searchPanel');
   UI.openElements(["searchPanel"]);

   //event to listen when page is updated in search overlay.
   Core.documentViewer.addEventListener("pageNumberUpdated", (page) => {
    this.$emit("changePage", page);
   });
  },
 },
 async mounted() {
  if(!this.initialized) {
   await this.initWebViewer();
   this.initialized = true;
  }
 },
};
